import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import pic02 from '../assets/images/map.png'
import pic01 from '../assets/images/cadastro.png'
import pic03 from '../assets/images/perfil.png'
import pic04 from '../assets/images/hand.png'
import pic05 from '../assets/images/pic05.jpg'
import pic06 from '../assets/images/pic06.jpg'

class HomeIndex extends React.Component {

    render() {
        function ArticleClick() {
            window.open("https://pdtsaopaulo.org.br/turmaboa/");
        }
        return (
            <Layout>
                <Helmet
                    title="B.Works"
                    meta={[
                        { name: 'description', content: 'Sample' },
                        { name: 'keywords', content: 'sample, something' },
                    ]}
                >
                </Helmet>

                <Banner />

                <div id="main">
                    <section id="one" className="tiles">
                        <article style={{ backgroundImage: `url(${pic01})` }} onClick={ArticleClick}>
                            <header className="major">
                                <h3>Banco de Inscritos</h3>
                                {/* <p>Ipsum dolor sit amet</p> */}
                            </header>
                            {/* <Link to="/landing" className="link primary"></Link> */}
                        </article>
                        <article style={{ backgroundImage: `url(${pic02})` }} onClick={ArticleClick}>
                            <header className="major">
                                <h3>Mapa Estratégico</h3>
                                {/* <p>feugiat amet tempus</p> */}
                            </header>
                            {/* <Link to="/landing" className="link primary"></Link> */}
                        </article>
                        <article style={{ backgroundImage: `url(${pic03})` }} onClick={ArticleClick}>
                            <header className="major">
                                <h3>Perfil do Candidato</h3>
                                {/* <p>feugiat amet tempus</p> */}
                            </header>
                            {/* <Link to="/landing" className="link primary"></Link> */}
                        </article>
                        <article style={{ backgroundImage: `url(${pic04})` }} onClick={ArticleClick}>
                            <header className="major">
                                <h3>Outros</h3>
                                {/* <p>feugiat amet tempus</p> */}
                            </header>
                            {/* <Link to="/landing" className="link primary"></Link> */}
                        </article>

                        {/* <article style={{ backgroundImage: `url(${pic02})` }}>
                            <header className="major">
                                <iframe style={{ position:"flex", width: "500px", height: "400px", margin: "0", padding:"0"}} src="https://sketchfab.com/models/e262d8d6a0da419da4d3b62800dd0858/embed?autospin=0.2&amp;autostart=1&amp;ui_controls=1&amp;ui_infos=1&amp;ui_inspector=1&amp;ui_stop=1&amp;ui_watermark=1&amp;ui_watermark_link=1" frameborder="0" allow="autoplay; fullscreen; vr" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                            </header>
                        </article> */}

                    </section>
                    {/* <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2>Massa libero</h2>
                            </header>
                            <p>Nullam et orci eu lorem consequat tincidunt vivamus et sagittis libero. Mauris aliquet magna magna sed nunc rhoncus pharetra. Pellentesque condimentum sem. In efficitur ligula tate urna. Maecenas laoreet massa vel lacinia pellentesque lorem ipsum dolor. Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis libero. Mauris aliquet magna magna sed nunc rhoncus amet pharetra et feugiat tempus.</p>
                            <ul className="actions">
                                <li><Link to="/landing" className="button next">Get Started</Link></li>
                            </ul>
                        </div>
                    </section> */}
                </div>

            </Layout>
        )
    }
}

export default HomeIndex