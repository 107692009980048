import React from 'react'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>Consultoria em soluções digitais | web</h1>
            </header>
            <div className="content">
                <p>Oferecemos soluções criativas e versáteis para facilitar o acesso a seu público na web.
                <br></br> Assessoria estratégica para campanhas e ativações.
                </p>
                {/* <ul className="actions">
                    <li><a href="#one" className="button next scrolly">Get Started</a></li>
                </ul> */}
            </div>
        </div>
    </section>
)

export default Banner
